import { React, useState } from 'react';
import {
  Flex,
  Text,
  keyframes,
  Image,
  Box,
  flexbox,
  Grid,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/workshops/background.png';
import rock1 from '../../assets/workshops/layer2left.svg';
import rock2 from '../../assets/workshops/layer2right.svg';
import fRocks from '../../assets/workshops/floatingRocks.svg';
import { workshop } from './WorkshopCardData';
import scroll from '../../assets/events/scroll.png';
import Card from '../CardStruct/Card';
import './Workshops.css';
export default function Workshops() {
  const [bgLoad, didBgLoad] = useState(false);
  let bgStatus = () => {
    didBgLoad(true);
  };
  const sideRocksKeyframes = keyframes`
    0% { transform: translate(-80vw,0);}
    25% { transform: translate(-60vw,0); }
    50% { transform: translate(-40vw,0); }
    75% { transform: translate(-20vw,0); }
    100% {transform: translate(0,0); }
  `;

  const sideRocks = `${bgLoad ? sideRocksKeyframes : ''} 0.7s ease-out`;
  const animationKeyframes = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation = `${bgLoad ? animationKeyframes : ''} 0.7s ease-out`;
  const animationKeyframes2 = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation2 = `${bgLoad ? animationKeyframes2 : ''} 1s ease-out`;
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      style={{ display: flexbox }}
    >
      <Flex flexDirection={'column'}>
        <Flex
          zIndex={7}
          flexDirection={'column'}
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'clip'}
        >
          <Box overflowX={'hidden'} position={'absolute'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
            <Box position={'absolute'} left={0} bottom={'2rem'}>
              <Flex w={'100vw'}>
                <Image
                  as={motion.img}
                  transform={'translate(-100vw,0)'}
                  animation={sideRocks}
                  src={rock1}
                  alt=''
                  sx={{ animationFillMode: 'forwards' }}
                />
                <Image
                  as={motion.img}
                  display={{ base: 'none', lg: 'block' }}
                  transform={'translate(-100vw,0)'}
                  animation={sideRocks}
                  src={rock2}
                  alt=''
                  sx={{ animationFillMode: 'forwards' }}
                />
              </Flex>
            </Box>
          </Box>

          <Box
            as={motion.text}
            transform={'translate(0,100vh)'}
            animation={animation}
            sx={{ animationFillMode: 'forwards' }}
            position={'relative'}
          >
            <Text
              className='heading'
              fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
              zIndex={2}
            >
              Workshops
            </Text>
            <Image
              as={motion.img}
              animation={animation2}
              sx={{ animationFillMode: 'forwards' }}
              transform={'translate(0,100vh)'}
              position={'absolute'}
              src={fRocks}
              alt=''
              zIndex={3}
              inset={0}
              left={'33%'}
            />
          </Box>
        </Flex>
        <Flex
          bgColor={'black'}
          bgGradient={
            'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.63) 0.01%, #000000 48.23%, rgba(0, 0, 0, 0.6) 100%);'
          }
          h='27.5625rem'
          mt={'-5rem'}
          mb={'-30rem'}
          zIndex={8}
          filter={'blur(1.5rem)'}
        ></Flex>
        <Flex
          h={'fit-content'}
          bgImg={scroll}
          bgPos={'center'}
          bgSize={'cover'}
          w='100%'
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex
            mt={'25rem'}
            zIndex={9}
            w={'85%'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Grid
              mt={'7rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {workshop.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
}
