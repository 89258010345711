import giggr from '../../assets/sponsors/giggr.png';
import crowdpad from '../../assets/sponsors/crowdpad.jpg';
import edvoy from '../../assets/sponsors/edvoy.png';
import suncrypto from '../../assets/sponsors/suncrypto.png';
import brainwonders from '../../assets/sponsors/brainwonders.png';
import havenspire from '../../assets/sponsors/havenspire.png';
import hoverRobotix from '../../assets/sponsors/hoverRobotix.png';
import idp from '../../assets/sponsors/idp.png';
import languify from '../../assets/sponsors/languify.png';
import networkPartner from '../../assets/sponsors/networkPartner.png';
import upsurge from '../../assets/sponsors/upsurge.png';
import timechain from '../../assets/sponsors/timechain.svg';
import edusession from '../../assets/sponsors/edusession.png';
import techobytes from '../../assets/sponsors/techobytes.png';
import fire from '../../assets/sponsors/5ire.svg';
import lollypop from '../../assets/sponsors/lollypop.svg';
import tagz from '../../assets/sponsors/tagz.png';
import govo from '../../assets/sponsors/govo.png';
import realme from '../../assets/sponsors/realme.jpg';
import campus from '../../assets/sponsors/campus.jpg';
import nodwin from '../../assets/sponsors/nodwin.jpg';
export const sponsorsData = [
  {
    partner: 'powered by',
    imag: `${crowdpad}`,
    link: 'http://crowdpad.co/',
  },
  {
    partner: 'In Association With',
    imag: `${giggr}`,
    link: 'https://www.giggr.app/',
  },
  {
    partner: 'Official SmartPhone partner',
    imag: `${realme}`,
    link: 'https://www.realme.com/global/',
  },
  {
    partner: 'official learning partner',
    imag: `${edvoy}`,
    link: 'https://edvoy.com/',
  },
  {
    partner: 'official foreign education partner',
    imag: `${idp}`,
    link: 'https://www.idp.com/india/',
  },
  {
    partner: 'Official Cryptoexchange Partner',
    imag: `${suncrypto}`,
    link: 'https://suncrypto.in/',
  },
  {
    partner: 'Powered By Sponsor of Qriosity Quiz',
    imag: `${brainwonders}`,
    link: 'https://www.brainwonders.in/',
  },
  {
    partner: 'Official Prize Partner of Corporate Panel',
    imag: `${havenspire}`,
    link: 'https://www.havenspire.in/',
  },
  {
    partner: 'Official Knowledge Partner of Corporate Panel',
    imag: `${upsurge}`,
    link: 'https://www.upsurge.club/',
  },
  {
    partner: 'Official Courses Partner',
    imag: `${languify}`,
    link: 'https://www.languify.in/',
  },
  {
    partner: 'Associate Partner of Roboficial Panel',
    imag: `${hoverRobotix}`,
    link: 'https://hoverrobotix.com/',
  },
  {
    partner: 'Official Network Partner of Matka',
    imag: `${networkPartner}`,
    link: 'https://www.expl.in/',
  },
  {
    partner: 'Powered By Sponsor of Blockchain Panel',
    link: 'https://www.timechainlabs.io',
    imag: timechain,
  },
  {
    partner: 'Title Sponsor of School Bag Panel',
    link: 'https://edusession.live/',
    imag: edusession,
  },
  {
    partner: 'Official Workshop Partner',
    link: 'https://techobytes.com/',
    imag: techobytes,
  },
  {
    partner: 'UI/UX Partner',
    link: 'https://lollypop.design/',
    imag: lollypop,
  },
  {
    partner: 'Official Fit Snacking Partner of Regalia',
    link: 'https://tagzfoods.com/',
    imag: tagz,
  },
  {
    partner: 'Official Title Sponsor of Regalia',
    link: 'https://www.5ire.org/',
    imag: fire,
  },
  {
    partner: "Official Music Partner of Quark'23",
    link: 'https://www.govo.life/',
    imag: govo,
  },
  {
    partner: 'powered by partner of Matka panel',
    link: 'https://nodwingaming.com/',
    imag: nodwin,
  },
  {
    partner: 'Official Aurora Nights partner',
    link: 'https://www.instagram.com/campussevents/',
    imag: campus,
  },
];
