import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, theme, Image } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './Components/Home/HomePage';
import Exhibitions from './Components/Exhibitions/Exhibitions';
import Workshops from './Components/Workshops/Workshops';
import Sponsors from './Components/Sponsors/Sponsors';
import Nights from './Components/Nights/Nights';
import Guest from './Components/Guest/Guest';
import Team from './Components/Team/Team';
import Events from './Components/Events/Events';
import Navbar from './Components/Navbar/Navbar';
import QuickLinks from './Components/QuickLinks/QuickLinks';
import loading from './assets/boot.gif';
import './App.css';
export default function App() {
  const delay = duration => {
    return new Promise(res => setTimeout(res, duration));
  };
  const [click, setClick] = useState(false);
  const [GIFcomp, endGIF] = useState(false);
  useEffect(() => {
    let load = async () => {
      await delay(4000);
      endGIF(true);
    };
    load();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Image
        display={GIFcomp ? 'none' : 'block'}
        src={GIFcomp ? 'none' : loading}
        position={'fixed'}
        w={'100vw'}
        h={'100vh'}
        zIndex={10000}
        objectFit={'cover'}
        id={'loading'}
        transform={'ease 500ms'}
      />
      <BrowserRouter>
        <Navbar />
        <Box textAlign='center' color={'white'} overflowX={'clip'}>
          <Routes>
            <Route
              path='/'
              element={<HomePage click={click} setClick={setClick} />}
            />
            <Route path='/events' element={<Events />} />
            <Route path='/workshops' element={<Workshops />} />
            <Route path='/nights' element={<Nights />} />
            <Route path='/team' element={<Team />} />
            <Route path='/exhibitions' element={<Exhibitions />} />
            <Route path='/guest' element={<Guest />} />
            <Route path='/sponsors' element={<Sponsors />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </Box>
        <QuickLinks click={click} />
      </BrowserRouter>
    </ChakraProvider>
  );
}
