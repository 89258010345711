import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@chakra-ui/react';
import robowars from '../../assets/cards/robowars.png';
import valorant from '../../assets/cards/valorant.png';
import product from '../../assets/cards/productManagement.png';
import ai from '../../assets/cards/AiforAi.png';
import nikhil from '../../assets/nights/nikhil.svg';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import CarouselCard from './CarouselCard';
import SwiperControl from './SwiperControl';

const SwiperCoverflow = () => {
  // const slideSet = [
  //   <SwiperSlide>
  //     <CarouselCard
  //       imag={robowars}
  //       title={'Robowars'}
  //       desc={
  //         'A metal-crushing,circuit-frying battle royale at robowars:The ultimate test of robotic supremacy'
  //       }
  //       moreText={'More events'}
  //       link={'/events'}
  //     />
  //   </SwiperSlide>,
  //   <SwiperSlide>
  //     <CarouselCard
  //       imag={valorant}
  //       title={'valorant'}
  //       desc={
  //         'Get your team ready and join the ultimate Valorant tournament. Prove your worth, master your aim, and become the champion. Are you up for the challenge?'
  //       }
  //       moreText={'More events'}
  //       link={'/events'}
  //     />
  //   </SwiperSlide>,
  //   <SwiperSlide>
  //     <CarouselCard
  //       imag={product}
  //       title={'product management 101'}
  //       desc={
  //         'Unlock the secrets to successful product management with our dynamic workshop, designed to empower you with the tools and strategies to drive growth and maximize impact.'
  //       }
  //       moreText={'More workshops'}
  //       link={'/workshops'}
  //     />
  //   </SwiperSlide>,
  //   <SwiperSlide>
  //     <CarouselCard
  //       imag={ai}
  //       title={'AI for Ai by ibm'}
  //       desc={
  //         "Explore the endless possibilities of AI and machine learning with our immersive workshop, where you'll learn to design, develop, and deploy intelligent applications that make a difference in the world."
  //       }
  //       moreText={'More workshops'}
  //       link={'/workshops'}
  //     />
  //   </SwiperSlide>,
  //   <SwiperSlide>
  //     <CarouselCard
  //       imag={nikhil}
  //       title={'nikhil d’souza'}
  //       desc={
  //         'Indian singer-songwriter known for his soulful and melodious voice, best heard in songs like "Shaam" and "Mere Bina"'
  //       }
  //       moreText={'More performances'}
  //       link={'/nights'}
  //     />
  //   </SwiperSlide>,
  // ];
  // const [slides, setSlides] = useState(slideSet);
  const [isEnd, setIsEnd] = useState(false);
  const [isBegin, setIsBegin] = useState(false);
  // let newSlides = Array.from(slides);
  return (
    <Box>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        // loop={true}
        slidesPerView={5}
        initialSlide={3}
        coverflowEffect={{
          rotate: 0,
          stretch: -0,
          depth: 10,
          modifier: 1,
          scale: 0.9,
        }}
        onReachEnd={() => {
          setIsEnd(true);
        }}
        onReachBeginning={() => setIsBegin(true)}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        // className='swiper_container'
        // initialSlide={2}
        // onSlideNextTransitionEnd={swiper => {
        //   // newSlides.push(newSlides.shift());
        //   // swiper.slidePrev(0, false);
        //   // setSlides(newSlides);
        // }}
        // onSlidePrevTransitionEnd={swiper => {
        //   // newSlides.unshift(newSlides.pop());
        //   // swiper.slideNext(0, false);
        //   // setSlides(newSlides);
        // }}
      >
        <SwiperSlide>
          <CarouselCard
            imag={robowars}
            title={'Robowars'}
            desc={
              'A metal-crushing,circuit-frying battle royale at robowars:The ultimate test of robotic supremacy'
            }
            moreText={'More events'}
            link={'/events'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselCard
            imag={valorant}
            title={'valorant'}
            desc={
              'Get your team ready and join the ultimate Valorant tournament. Prove your worth, master your aim, and become the champion. Are you up for the challenge?'
            }
            moreText={'More events'}
            link={'/events'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselCard
            imag={product}
            title={'product management 101'}
            desc={
              'Unlock the secrets to successful product management with our dynamic workshop, designed to empower you with the tools and strategies to drive growth and maximize impact.'
            }
            moreText={'More workshops'}
            link={'/workshops'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselCard
            imag={ai}
            title={'AI for Ai by ibm'}
            desc={
              "Explore the endless possibilities of AI and machine learning with our immersive workshop, where you'll learn to design, develop, and deploy intelligent applications that make a difference in the world."
            }
            moreText={'More workshops'}
            link={'/workshops'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselCard
            imag={nikhil}
            title={'nikhil d’souza'}
            desc={
              'Indian singer-songwriter known for his soulful and melodious voice, best heard in songs like "Shaam" and "Mere Bina"'
            }
            moreText={'More performances'}
            link={'/nights'}
          />
        </SwiperSlide>
        <SwiperControl
          isEnd={isEnd}
          isBegin={isBegin}
          setIsBegin={setIsBegin}
          setIsEnd={setIsEnd}
        />
      </Swiper>
    </Box>
  );
};

export default SwiperCoverflow;
