import divakar from '../../assets/guest/guestLectures/divakar.png';
import ashok from '../../assets/guest/guestLectures/ashok.png';
import jayaprakash from '../../assets/guest/guestLectures/jayaprakash.png';
import pkdhaka from '../../assets/guest/guestLectures/pkdhaka.png';
import ranodeep from '../../assets/guest/guestLectures/ranodeep.png';
import ravi from '../../assets/guest/guestLectures/ravi.png';
export const guestLectures = [
  {
    name: 'divakar Vaish',
    imag: `${divakar}`,
    desgn: 'cofounder, agva healthcare',
  },
  {
    name: 'jayaprakash gupta',
    imag: `${jayaprakash}`,
    desgn: 'founder, dhan',
  },
  {
    name: 'ravi kabra',
    imag: `${ravi}`,
    desgn: 'ceo, skippi',
  },
  {
    name: 'Ranodeep saha',
    imag: `${ranodeep}`,
    desgn: 'cofounder, rare planet',
  },
  {
    name: 'Wing Commander pk dhaka',
    imag: `${pkdhaka}`,
    desgn: 'Indian air force',
  },
  {
    name: 'ashok barat',
    imag: `${ashok}`,
    desgn: 'managing director, forbes',
  },
];
