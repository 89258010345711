import { Image } from '@chakra-ui/image';
import { Box, Flex, Text, VStack } from '@chakra-ui/layout';
import { Fade } from '@chakra-ui/transition';
import { useState } from 'react';

export default function Night({
  artist,
  day,
  image,
  description,
  comingSoon,
  day3,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return !comingSoon ? (
    <Flex
      justifyContent='right'
      alignItems='flex-end'
      backgroundColor='black'
      w='100%'
      direction={'column'}
      position={'relative'}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <Image
        src={image}
        w={'80%'}
        h={'100%'}
        objectFit={!day3 ? 'cover' : 'contain'}
        objectPosition={'right'}
        mr={day3 ? '4.5rem' : '0'}
        position={'absolute'}
        right={0}
        filter={isHovered ? 'none' : 'saturate(0)'}
        transition={'all ease 500ms'}
      />
      <Box
        h={{ base: '50vw', lg: '42rem' }}
        w={{ base: '100vw' }}
        bg={`linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 40%)`}
        zIndex={1}
      >
        <Fade in={isHovered}>
          <VStack w='fit-content'>
            <Box
              alignContent='left'
              w={'35.3125rem'}
              mt={{ base: '0', md: '5.75rem' }}
              textAlign={'left'}
              ps={{ base: '1rem', md: '3rem' }}
            >
              <Text
                mt={{ base: '3.5625rem' }}
                className='heading'
                fontSize={{ base: '2.1875rem', lg: '1.9375rem' }}
                zIndex={2}
                fontWeight='400'
              >
                DAY {day}
              </Text>
              <Text
                alignContent={'left'}
                className='heading'
                fontSize={{ base: '1.05rem', lg: '3rem' }}
                fontWeight='400'
              >
                {artist}
              </Text>
              <Text
                alignContent='left'
                fontSize={'1rem'}
                fontWeight='400'
                display={{ base: 'none', md: 'unset' }}
              >
                {description}
              </Text>
            </Box>
          </VStack>
        </Fade>
      </Box>
    </Flex>
  ) : (
    <Flex
      justifyContent='right'
      alignItems='flex-end'
      backgroundColor='black'
      w='100%'
      direction={'column'}
    >
      <Box
        h={{ base: '50vw', lg: '42rem' }}
        w={{ base: '100vw' }}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
        bg={`url(${image});`}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Fade in={isHovered}>
          <VStack w='100%'>
            <Box
              alignContent='center'
              w={'34.3125rem'}
              mt={{ base: '0', md: '1.75rem' }}
              textAlign={'center'}
              width={'100%'}
            >
              <Text
                mt={{ base: '2rem', md: '3.5625rem' }}
                className='heading'
                fontSize={{ base: '2.1875rem', lg: '1.9375rem' }}
                zIndex={2}
                fontWeight='400'
              >
                DAY {day}
              </Text>
              <Text
                className='heading'
                fontSize={{ base: '2.5rem', lg: '6.25rem' }}
                fontWeight='400'
              >
                Coming soon
              </Text>
            </Box>
          </VStack>
        </Fade>
      </Box>
    </Flex>
  );
}
