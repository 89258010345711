import { useSwiper } from 'swiper/react';
import left from '../../assets/carousel/left.svg';
import right from '../../assets/carousel/right.svg';
import { HStack, Image, Box } from '@chakra-ui/react';

const SwiperControl = ({ isEnd, setIsEnd, isBegin, setIsBegin }) => {
  const swiper = useSwiper();
  return (
    <HStack
      mt='3.125rem'
      className='slider-controler'
      justifyContent='center'
      gap='4.6875rem'
    >
      <Box
        display={isBegin ? 'none' : 'block'}
        className='.swiper-button-prev slider-arrow'
        onClick={() => {
          swiper.slidePrev(500, false);
          if (isEnd) {
            setIsEnd(false);
          }
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <Image src={left} alt='Left Arrow' />
      </Box>
      <Box
        display={isEnd ? 'none' : 'block'}
        className='.swiper-button-next slider-arrow'
        onClick={() => {
          swiper.slideNext(500, false);
          if (isBegin) {
            setIsBegin(false);
          }
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <Image src={right} alt='Right Arrow' />
      </Box>
    </HStack>
  );
};

export default SwiperControl;
