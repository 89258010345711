import { React, useState } from 'react';
import {
  Flex,
  Text,
  VStack,
  keyframes,
  Image,
  Box,
  flexbox,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/exhibitions/background.png';
import './Exhibitions.css';
import ExhibitImg from '../CardStruct/ExhibitImg';
import exhibit2 from '../../assets/exhibitions/exhibit2.JPG';
import exhibit1 from '../../assets/exhibitions/exhibit1.JPG';
import exhibit3 from '../../assets/exhibitions/exhibit3.jpeg';
import scroll from '../../assets/events/scroll.png';
export default function Exhibitions() {
  const [bgLoad, didBgLoad] = useState(false);
  let bgStatus = () => {
    didBgLoad(true);
  };
  const animationKeyframes = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation = `${bgLoad ? animationKeyframes : ''} 1s ease-out`;
  return (
    <Flex direction={'column'}>
      <motion.div
        initial={{ x: 100 }}
        animate={{ x: 0 }}
        exit={{ x: -100 }}
        style={{ display: flexbox }}
      >
        <Flex
          flexdirection={'column'}
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'clip'}
        >
          <Box position={'absolute'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
          </Box>
          <VStack spacing={'10rem'} mt={'10rem'}>
            <Text
              as={motion.text}
              transform={'translate(0,100vh)'}
              className='heading'
              fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
              animation={animation}
              sx={{ animationFillMode: 'forwards' }}
            >
              exhibitions
            </Text>
          </VStack>
        </Flex>
      </motion.div>
      <Flex
        bgColor={'black'}
        bgGradient={
          'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.63) 0.01%, #000000 48.23%, rgba(0, 0, 0, 0.6) 100%);'
        }
        h='27.5625rem'
        mt={'-5rem'}
        mb={'-25rem'}
        zIndex={8}
        filter={'blur(1.5rem)'}
      ></Flex>
      <Flex
        direction={'column'}
        bgImage={scroll}
        bgPos={'center'}
        bgSize={'cover'}
      >
        <VStack zIndex={9} mb={'10rem'}>
          <Text className='heading' mt={'10rem'} mb={'5rem'}>
            Drona aviation
          </Text>
          <Flex direction={{ base: 'column', lg: 'row' }} alignItems={'center'}>
            <ExhibitImg imag={exhibit1} />
            <Box w={'10rem'} h={{ base: '5rem', lg: 'none' }}></Box>
            <ExhibitImg imag={exhibit2} />
          </Flex>
        </VStack>
        <VStack mb={'5rem'}>
          <Text className='heading' mb={'5rem'}>
            FT & S Mind control
          </Text>
          <Flex direction={{ base: 'column', lg: 'row' }} alignItems={'center'}>
            <ExhibitImg imag={exhibit3} />
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
}
