import nft from '../../assets/cards/nft.png';
import product from '../../assets/cards/productManagement.png';
import dsa from '../../assets/cards/dsa.png';
import ai from '../../assets/cards/AiforAi.png';
import uiux from '../../assets/cards/uiux.png';
import upstox from '../../assets/cards/upstox.png';
export const workshop = [
  {
    name: 'nft & web 3.0 by polygon',
    descr:
      'Step into the world of NFTs and Web 3.0 with our hands-on workshop and gain the skills to leverage the power of decentralized systems for your own projects.',
    link: '',
    imag: `${nft}`,
  },
  {
    name: 'sprinklr: product management 101',
    descr:
      'Unlock the secrets to successful product management with our dynamic workshop, designed to empower you with the tools and strategies to drive growth and maximize impact.',
    link: '',
    imag: `${product}`,
  },
  {
    name: 'DSA by geeksforgeeks',
    descr:
      'Master the fundamentals of data structures and algorithms and sharpen your problem-solving skills with our comprehensive workshop, designed for beginners and experienced programmers alike.',
    link: '',
    imag: `${dsa}`,
  },
  {
    name: 'AI for Ai by ibm',
    descr:
      "Explore the endless possibilities of AI and machine learning with our immersive workshop, where you'll learn to design, develop, and deploy intelligent applications that make a difference in the world.",
    link: '',
    imag: `${ai}`,
  },
  {
    name: 'ui/ux by lollypop designs',
    descr:
      'Refine your design skills and create unforgettable user experiences with our dynamic UI/UX workshop, led by industry experts and packed with practical insights and techniques.',
    link: '',
    imag: `${uiux}`,
  },
  {
    name: 'stock trading with upstox',
    descr:
      'Take your stock trading skills to the next level with Upstox and discover the secrets to successful investing at our exclusive workshop.',
    link: '',
    imag: `${upstox}`,
  },
];
