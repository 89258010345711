import '@fontsource/source-sans-pro';
import React from 'react';
import {
  Box,
  Flex,
  Image,
  HStack,
  useDisclosure,
  IconButton,
  VStack,
  Slide,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import navbarLogo from '../../assets/homePageAnimation/logo.png';
import './Navbar.css';

export default function Navbar() {
  const navigate = useNavigate();
  let homeRoute = () => {
    navigate('/');
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      position={isOpen ? 'sticky' : 'absolute'}
      top={0}
      zIndex={100}
      h='fit-content'
      bgGradient={
        'linear-gradient(180deg, rgba(0, 0, 0, 0.56) 29.17%, rgba(0, 0, 0, 0) 100%);'
      }
      direction='row'
      justifyContent='space-between'
      alignContent={'center'}
      w={'100%'}
      overflowX={'clip'}
      color={'white'}
    >
      <Flex
        pt='2.5rem'
        ps='5rem'
        pe='4.25rem'
        mb={isOpen ? '-5.625rem' : '4rem'}
        h='3.125rem'
        w='100%'
        color='white'
        backgroundColor='transparent'
        borderRadius='4.5rem'
        justifyContent={'flex-end'}
        display={{ base: 'none', lg: 'flex' }}
      >
        <HStack
          mt='0.9375rem'
          mb='0.9375rem'
          ms='2.125rem'
          me='2.125rem'
          justifyContent={'space-evenly'}
          w={'100%'}
        >
          <Image
            h={'3.375rem'}
            w={'10rem'}
            src={navbarLogo}
            alt="Quark '23 Logo"
            _hover={{ cursor: 'pointer' }}
            onClick={homeRoute}
            zIndex={20}
          />
          <Link to='events' className='NavBar'>
            Events
          </Link>
          <Link to='workshops' className='NavBar'>
            Workshops
          </Link>
          <Link to='nights' className='NavBar'>
            Nights
          </Link>
          <Link to='guest' className='NavBar'>
            Guest Lectures
          </Link>
          <Link to='exhibitions' className='NavBar'>
            Exhibitions
          </Link>
          <Link to='sponsors' className='NavBar'>
            Sponsors
          </Link>
          <Link to='team' className='NavBar'>
            Our Team
          </Link>
        </HStack>
      </Flex>
      <Flex
        position={'absolute'}
        zIndex={100}
        display={{ lg: 'none' }}
        w={'100vw'}
        p={'1rem'}
      >
        <HStack justifyContent={'space-between'}>
          <Image
            h={'3.375rem'}
            w={'10rem'}
            src={navbarLogo}
            alt="Quark '23 Logo"
            _hover={{ cursor: 'pointer' }}
            onClick={homeRoute}
          />
          <Box>
            <IconButton
              variant={'unstyled'}
              color={'white'}
              bg='transparent'
              size={'lg'}
              icon={
                isOpen ? (
                  <HamburgerIcon
                    boxSize={8}
                    transform={'rotate(-90deg)'}
                    transitionDuration={'0.5s'}
                  />
                ) : (
                  <HamburgerIcon boxSize={7} transitionDuration={'0.5s'} />
                )
              }
              aria-label={'Open Menu'}
              display={{ lg: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
          </Box>
        </HStack>
      </Flex>
      <Slide direction='right' in={isOpen}>
        <Box
          overflowX={'hidden'}
          fontWeight={700}
          fontSize='1.5rem'
          bg='blackAlpha.900'
          height={'100vh'}
          display={{ lg: 'none' }}
          pb={4}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          zIndex={100}
          transitionDuration={'0.5s'}
          onClick={onClose}
          paddingTop={'5rem'}
        >
          <Flex display={{ lg: 'none' }}>
            <VStack alignItems={'center'} spacing={3} width={'100%'}>
              <Link to='events' className='NavBar'>
                Events
              </Link>
              <Link to='workshops' className='NavBar'>
                Workshops
              </Link>
              <Link to='nights' className='NavBar'>
                Nights
              </Link>
              <Link to='guest' className='NavBar'>
                Guest Lectures
              </Link>
              <Link to='exhibitions' className='NavBar'>
                Exhibitions
              </Link>
              <Link to='sponsors' className='NavBar'>
                Sponsors
              </Link>
              <Link to='team' className='NavBar'>
                Our Team
              </Link>
            </VStack>
          </Flex>
        </Box>
      </Slide>
    </Flex>
  );
}
