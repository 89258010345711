import { React, useState } from 'react';
import {
  Flex,
  Image,
  Box,
  Text,
  keyframes,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import Quark from '../../assets/homeAnimNew/Quark.png';
import bg from '../../assets/homeAnimNew/bg.png';
import rock1 from '../../assets/homeAnimNew/rock1.png';
import rock2 from '../../assets/homeAnimNew/rock2.png';
import rock3 from '../../assets/homeAnimNew/rock3.png';
import rock4 from '../../assets/homeAnimNew/rock4.png';
import rock6 from '../../assets/homeAnimNew/rock6.png';
import rock5 from '../../assets/homeAnimNew/rock5.png';
// import gr1 from '../../assets/homeAnimNew/gr1.png';
// import gr2 from '../../assets/homeAnimNew/gr2.png';
// import gr3 from '../../assets/homeAnimNew/gr3.png';
// import planet from '../../assets/homeAnimNew/planet.png';
import Countdown from 'react-countdown';
import './Home.css';
export default function HomeAnimNew({ click, setClick }) {
  function delay(duration) {
    return new Promise(res => setTimeout(res, duration));
  }
  const animationKeyframes1 = keyframes`
  0% { transform: translate(-120%,0)}
  25% { transform: translate(-80%,0)}
  50% { transform: translate(-40%,0)}
  75% { transform: translate(-20%,0)}
  100% { transform: translate(0%,0)}
`;
  const animation1 = `${animationKeyframes1} 1.2s ease-in`;
  const animationKeyframes2 = keyframes`
  0% { transform: translate(120%,0)}
  25% { transform: translate(80%,0)}
  50% { transform: translate(40%,0)}
  75% { transform: translate(20%,0)}
  100% { transform: translate(0%,0)}
`;
  const animation2 = `${animationKeyframes2} 1.2s ease-in`;
  const animationKeyframes3 = keyframes`
  0% { transform: translate(0,120%)}
  25% { transform: translate(0,80%)}
  50% { transform: translate(0,40%)}
  75% { transform: translate(0,20%)}
  100% { transform: translate(0,0%)}
`;
  const animation3 = `${animationKeyframes3} 1.2s ease-in`;

  const animationKeyframes4 = keyframes`
  0% { transform: translate(0,-120%)  }
  25% { transform: translate(0,-80%)  }
  50% { transform: translate(0,-40%)  }
  75% { transform: translate(0,-20%)  }
  100% { transform: translate(0,0%)  }
`;
  const animation4 = `${animationKeyframes4} 1.2s ease-in`;
  const TimerEnd = () => {
    <Text className='timer' fontSize={{ base: '1.8rem', lg: '3rem' }}>
      Event is Live!
    </Text>;
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <TimerEnd />;
    } else {
      return (
        <VStack>
          <Text className='timer' fontSize={{ base: '1.8rem', lg: '3rem' }}>
            Time remaining
          </Text>
          <Text
            className='timer'
            color={'white'}
            fontSize={{ base: '1.8rem', lg: '3rem' }}
          >
            {days}d {hours}h {minutes}m {seconds}s
          </Text>
        </VStack>
      );
    }
  };

  const ChakraBox = chakra(motion.div, {
    shouldForwardProp: prop =>
      isValidMotionProp(prop) || shouldForwardProp(prop),
  });

  // const [click, setClick] = useState(false);
  let clickCheck = () => {
    if (!click) {
      setClick(true);
    }
  };

  const [showTimer, setShowTimer] = useState(false);
  const [stopAnim, setStopAnim] = useState(false);

  let animEnd = async () => {
    setStopAnim(true);
    await delay(600);
    setShowTimer(true);
  };

  return (
    <Flex
      onClick={clickCheck}
      h={'100vh'}
      w={'100vw'}
      direction={'column'}
      bgImage={bg}
      justifyContent={'center'}
      alignItems={'center'}
      bgSize={'cover'}
      overflowX={'clip'}
      zIndex={click ? 7 : 900}
      overflow={'hidden'}
    >
      <Flex display={showTimer ? 'flex' : 'none'} mb={'2rem'}>
        <ChakraBox>
          <VStack spacing={'2rem'}>
            <Text
              align={'center'}
              className='timer'
              fontSize={{ base: '1.6rem', lg: '3.5rem' }}
            >
              <HStack
                display={{ base: 'none', lg: 'flex' }}
                spacing={'2rem'}
                wrap={'wrap'}
              >
                <Box
                  as={motion.div}
                  animation={animation1}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  Welcome{' '}
                </Box>{' '}
                <Box
                  animation={animation4}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  to
                </Box>{' '}
                <Box
                  animation={animation4}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  the{' '}
                </Box>
                <Box
                  animation={animation4}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  16th
                </Box>{' '}
                <Box
                  animation={animation2}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  quark
                </Box>{' '}
                <Box
                  animation={animation2}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  of
                </Box>
              </HStack>
              <VStack display={{ base: 'flex', lg: 'none' }} spacing={'1rem'}>
                <HStack>
                  <Box
                    as={motion.div}
                    animation={animation1}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    Welcome{' '}
                  </Box>{' '}
                  <Box
                    animation={animation4}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    to
                  </Box>
                </HStack>{' '}
                <HStack>
                  <Box
                    animation={animation4}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    the{' '}
                  </Box>
                  <Box
                    animation={animation4}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    16th
                  </Box>{' '}
                </HStack>
                <HStack>
                  <Box
                    animation={animation2}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    quark
                  </Box>{' '}
                  <Box
                    animation={animation2}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    of
                  </Box>
                </HStack>
                <HStack>
                  <Box
                    animation={animation1}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    bits
                  </Box>{' '}
                  <Box
                    animation={animation3}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    pilani
                  </Box>{' '}
                </HStack>
                <HStack>
                  <Box
                    animation={animation3}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    kk{' '}
                  </Box>
                  <Box
                    animation={animation3}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    birla
                  </Box>
                </HStack>
                <HStack>
                  {' '}
                  <Box
                    animation={animation2}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    goa
                  </Box>{' '}
                  <Box
                    animation={animation2}
                    sx={{ animationFillMode: 'forwards' }}
                  >
                    campus
                  </Box>
                </HStack>
              </VStack>
            </Text>
            <Text
              align={'center'}
              className='timer'
              fontSize={{ base: '1.8rem', lg: '3.5rem' }}
            >
              <HStack display={{ base: 'none', lg: 'flex' }} spacing={'2rem'}>
                <Box
                  animation={animation1}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  bits
                </Box>{' '}
                <Box
                  animation={animation3}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  pilani
                </Box>{' '}
                <Box
                  animation={animation3}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  kk{' '}
                </Box>
                <Box
                  animation={animation3}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  birla
                </Box>{' '}
                <Box
                  animation={animation2}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  goa
                </Box>{' '}
                <Box
                  animation={animation2}
                  sx={{ animationFillMode: 'forwards' }}
                >
                  campus
                </Box>
              </HStack>
            </Text>
          </VStack>
        </ChakraBox>
      </Flex>
      <Flex display={showTimer ? 'flex' : 'none'}>
        <ChakraBox
          opacity={0}
          animate={{
            opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          }}
        >
          <Countdown date={'2023-03-24T00:00:00+05:30'} renderer={renderer}>
            <TimerEnd />
          </Countdown>
        </ChakraBox>
      </Flex>
      <Box display={!stopAnim ? 'flex' : 'none'} position={'relative'}>
        <ChakraBox
          onAnimationComplete={animEnd}
          animate={
            click
              ? { scale: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6.5, 7] }
              : {}
          }
        >
          <Image
            w={{ base: '21.875rem', lg: '43.75rem' }}
            h={{ base: '7.675rem', lg: '15.35rem' }}
            src={Quark}
          />
        </ChakraBox>
        <ChakraBox
          animate={
            click
              ? {
                  transform: [
                    'translate(-10%,-10%) rotate(0.1turn)',
                    'translate(-30%,-30%) rotate(0.2turn)',
                    'translate(-50%,-50%) rotate(0.3turn)',
                    'translate(-70%,-70%) rotate(0.4turn)',
                    'translate(-90%,-90%) rotate(0.5turn)',
                    'translate(-110%,-110%) rotate(0.6turn)',
                    'translate(-130%,-130%) rotate(0.7turn)',
                    'translate(-150%,-150%) rotate(0.8turn)',
                    'translate(-170%,-170%) rotate(0.9turn)',
                    'translate(-250%,-250%) rotate(1turn)',
                    'translate(-450%,-450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
          position={'absolute'}
          left={'-15%'}
          top={'-40%'}
        >
          <Image
            src={rock1}
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
          />
        </ChakraBox>
        <ChakraBox
          position={'absolute'}
          left={'25%'}
          bottom={'-90%'}
          animate={
            click
              ? {
                  transform: [
                    'translate(-10%,-10%) rotate(0.1turn)',
                    'translate(-30%,-30%) rotate(0.2turn)',
                    'translate(-50%,-50%) rotate(0.3turn)',
                    'translate(-70%,-70%) rotate(0.4turn)',
                    'translate(-90%,-90%) rotate(0.5turn)',
                    'translate(-110%,-110%) rotate(0.6turn)',
                    'translate(-130%,-130%) rotate(0.7turn)',
                    'translate(-150%,-150%) rotate(0.8turn)',
                    'translate(-170%,-170%) rotate(0.9turn)',
                    'translate(-250%,-250%) rotate(1turn)',
                    'translate(-450%,-450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
        >
          <Image
            src={rock2}
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
          />
        </ChakraBox>
        <ChakraBox
          position={'absolute'}
          right={0}
          bottom={'-80%'}
          animate={
            click
              ? {
                  transform: [
                    'translate(-10%,10%) rotate(0.1turn)',
                    'translate(-30%,30%) rotate(0.2turn)',
                    'translate(-50%,50%) rotate(0.3turn)',
                    'translate(-70%,70%) rotate(0.4turn)',
                    'translate(-90%,90%) rotate(0.5turn)',
                    'translate(-110%,110%) rotate(0.6turn)',
                    'translate(-130%,130%) rotate(0.7turn)',
                    'translate(-150%,150%) rotate(0.8turn)',
                    'translate(-170%,170%) rotate(0.9turn)',
                    'translate(-250%,250%) rotate(1turn)',
                    'translate(-450%,450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
        >
          <Image
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
            src={rock3}
          />
        </ChakraBox>
        <ChakraBox
          position={'absolute'}
          right={0}
          top={'-80%'}
          animate={
            click
              ? {
                  transform: [
                    'translate(10%,-10%) rotate(0.1turn)',
                    'translate(30%,-30%) rotate(0.2turn)',
                    'translate(50%,-50%) rotate(0.3turn)',
                    'translate(70%,-70%) rotate(0.4turn)',
                    'translate(90%,-90%) rotate(0.5turn)',
                    'translate(110%,-110%) rotate(0.6turn)',
                    'translate(130%,-130%) rotate(0.7turn)',
                    'translate(150%,-150%) rotate(0.8turn)',
                    'translate(170%,-170%) rotate(0.9turn)',
                    'translate(250%,-250%) rotate(1turn)',
                    'translate(450%,-450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
        >
          <Image
            src={rock4}
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
          />
        </ChakraBox>
        <ChakraBox
          position={'absolute'}
          right={'-12rem'}
          bottom={0}
          animate={
            click
              ? {
                  transform: [
                    'translate(10%,-10%) rotate(0.1turn)',
                    'translate(30%,-30%) rotate(0.2turn)',
                    'translate(50%,-50%) rotate(0.3turn)',
                    'translate(70%,-70%) rotate(0.4turn)',
                    'translate(90%,-90%) rotate(0.5turn)',
                    'translate(110%,-110%) rotate(0.6turn)',
                    'translate(130%,-130%) rotate(0.7turn)',
                    'translate(150%,-150%) rotate(0.8turn)',
                    'translate(170%,-170%) rotate(0.9turn)',
                    'translate(250%,-250%) rotate(1turn)',
                    'translate(450%,-450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
        >
          <Image
            src={rock6}
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
          />
        </ChakraBox>
        <ChakraBox
          position={'absolute'}
          left={'25%'}
          top={'-80%'}
          animate={
            click
              ? {
                  transform: [
                    'translate(10%,-10%) rotate(0.1turn)',
                    'translate(30%,-30%) rotate(0.2turn)',
                    'translate(50%,-50%) rotate(0.3turn)',
                    'translate(70%,-70%) rotate(0.4turn)',
                    'translate(90%,-90%) rotate(0.5turn)',
                    'translate(110%,-110%) rotate(0.6turn)',
                    'translate(130%,-130%) rotate(0.7turn)',
                    'translate(150%,-150%) rotate(0.8turn)',
                    'translate(170%,-170%) rotate(0.9turn)',
                    'translate(250%,-250%) rotate(1turn)',
                    'translate(450%,-450%) rotate(1.2turn)',
                  ],
                }
              : {}
          }
        >
          <Image
            src={rock5}
            h={{ base: '4.572rem', lg: '11.43rem' }}
            w={{ base: '3.748rem', lg: '9.37rem' }}
          />
        </ChakraBox>
      </Box>
      <ChakraBox
        display={!stopAnim ? 'flex' : 'none'}
        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1, 0.75, 0.5, 0.25, 0] }}
        transition={{
          duration: 1.2,
          repeat: Infinity,
        }}
      >
        <Text
          transform={'translate(0,9rem)'}
          className='homeText'
          fontSize={{ base: '1.5rem', lg: '2rem' }}
          align={'center'}
        >
          Travel beyond the horizon
        </Text>
      </ChakraBox>
      <ChakraBox
        display={!stopAnim ? 'flex' : 'none'}
        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1, 0.75, 0.5, 0.25, 0] }}
        transition={{
          duration: 1.2,
          repeat: Infinity,
        }}
      >
        <Text
          transform={'translate(0,9rem)'}
          className='homeText'
          fontSize={{ base: '0.75rem', lg: '1rem' }}
          align={'center'}
        >
          Tap to Enter
        </Text>
      </ChakraBox>
    </Flex>
  );
}
