import { React, useState } from 'react';
import {
  Flex,
  Text,
  VStack,
  keyframes,
  Image,
  Box,
  flexbox,
  Grid,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/guest/background.png';
import './Guest.css';
import { guestLectures } from './guestData';
import GuestCard from '../CardStruct/GuestCard';
import scroll from '../../assets/events/scroll.png';
export default function Guest() {
  const [bgLoad, didBgLoad] = useState(false);
  let bgStatus = () => {
    didBgLoad(true);
  };
  const animationKeyframes = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation = `${bgLoad ? animationKeyframes : ''} 1s ease-out`;
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      style={{ display: flexbox }}
    >
      <Flex direction={'column'}>
        <Flex
          flexdirection={'column'}
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'clip'}
        >
          <Box position={'absolute'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
          </Box>
          <VStack spacing={'10rem'}>
            <Text
              as={motion.text}
              transform={'translate(0,100vh)'}
              className='heading'
              fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
              animation={animation}
              sx={{ animationFillMode: 'forwards' }}
              flexWrap={'wrap'}
            >
              Guest Lectures
            </Text>
          </VStack>
        </Flex>
        <Flex
          bgColor={'black'}
          bgGradient={
            'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.63) 0.01%, #000000 48.23%, rgba(0, 0, 0, 0.6) 100%);'
          }
          h='27.5625rem'
          mt={'-5rem'}
          mb={'-30rem'}
          zIndex={8}
          filter={'blur(1.5rem)'}
        ></Flex>
        <Flex
          h={'fit-content'}
          bgImg={scroll}
          bgPos={'center'}
          bgSize={'cover'}
          w='100%'
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex
            mt={'25rem'}
            zIndex={9}
            w={'85%'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Grid
              mt={'7rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {guestLectures.map(elem => {
                return (
                  <GuestCard
                    key={elem.name}
                    name={elem.name}
                    imag={elem.imag}
                    desgn={elem.desgn}
                  />
                );
              })}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
}
