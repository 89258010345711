import React from 'react';
import {
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import bg from '../../assets/events/scroll.png';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutube,
} from 'react-icons/fa';

const QuickLinks = ({ click }) => {
  return (
    <Flex
      display={click ? 'flex' : 'none'}
      h='fit-content'
      w='100%'
      backgroundImage={bg}
      bgPos={'center'}
      bgSize={'cover'}
      overflowX={'clip'}
      backgroundColor={'blackAlpha.600'}
      direction={'column'}
    >
      <Flex
        h='fit-content'
        w='100%'
        bgPos={'center'}
        bgSize={'cover'}
        justifyContent='space-between'
        pt='2rem'
        ps='5rem'
        pe='5rem'
        direction={{ base: 'column', md: 'row' }}
      >
        <Box>
          <Text
            className='heading'
            color='white'
            fontWeight='400'
            fontSize='1.25rem'
            lineHeight='1.26rem'
          >
            QUICK LINKS
          </Text>
          <Flex
            justifyContent='space-between'
            pb='1.875rem'
            pt='2.3125rem'
            gap='3.6875rem'
          >
            <Box>
              <VStack align='left'>
                <Link to='/'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8rem'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Home
                  </Text>
                </Link>
                <Link to='events'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8rem'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Events
                  </Text>
                </Link>
                <Link to='workshops'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8em'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Workshops
                  </Text>
                </Link>
                <Link to='exhibitions'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8em'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Exhibitions
                  </Text>
                </Link>
              </VStack>
            </Box>
            <Box>
              <VStack align='left'>
                <Link to='nights'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8rem'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Nights
                  </Text>
                </Link>
                <Link to='guest'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8rem'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Guest Lectures
                  </Text>
                </Link>
                <Link to='sponsors'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8em'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Sponsors
                  </Text>
                </Link>
                <Link to='team'>
                  <Text
                    fontWeight='300'
                    fontSize='0.8em'
                    fontFamily='Source Sans Pro'
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    lineHeight='1.25rem'
                    color='white'
                  >
                    Our Team
                  </Text>
                </Link>
              </VStack>
            </Box>
          </Flex>
        </Box>
        <Flex
          direction={'column'}
          alignItems={{ base: 'unset', md: 'center' }}
          marginBottom='2.5rem'
        >
          <Text
            className='heading'
            color='white'
            fontWeight='400'
            fontSize='1.25rem'
            lineHeight='1.26rem'
            marginBottom='1.5rem'
          >
            SOCIALS
          </Text>
          <ButtonGroup variant={'ghost'} color={'white'} isAttached>
            <Link to='https://www.instagram.com/bitsquark/' isExternal>
              <IconButton
                icon={<FaInstagram />}
                _hover={{ boxShadow: '0 0 1rem white' }}
              />
            </Link>
            <Link to='https://www.youtube.com/@QuarkBITSPilaniGoa' isExternal>
              <IconButton
                icon={<FaYoutube />}
                _hover={{ boxShadow: '0 0 1rem white' }}
              />
            </Link>
            <Link to='https://www.facebook.com/bitsquark/' isExternal>
              <IconButton
                icon={<FaFacebook />}
                _hover={{ boxShadow: '0 0 1rem white' }}
              />
            </Link>
            <Link
              to='https://www.linkedin.com/company/quark-bits-pilani-goa'
              isExternal
            >
              <IconButton
                icon={<FaLinkedin />}
                _hover={{ boxShadow: '0 0 1rem white' }}
              />
            </Link>
            <Link to='https://twitter.com/bitsquark?lang=en' isExternal>
              <IconButton
                icon={<FaTwitterSquare />}
                _hover={{ boxShadow: '0 0 1rem white' }}
              />
            </Link>
          </ButtonGroup>
        </Flex>
        <Box>
          <Text
            className='heading'
            color='white'
            fontWeight='400'
            fontSize='1.25rem'
            lineHeight='1.26rem'
          >
            BITS PILANI GOA
          </Text>
          <Flex
            justifyContent='flex-end'
            align='right'
            pb='1.875rem'
            pt='2.3125rem'
            gap='3.6875rem'
          >
            <Box>
              <VStack alignContent='right' align='right'>
                <Text
                  fontWeight='300'
                  fontSize='0.8rem'
                  fontFamily='Source Sans Pro'
                  lineHeight='1.25rem'
                  color='white'
                >
                  NH 17B, Bypass Road, Zuarinagar,
                </Text>
                <Text
                  fontWeight='300'
                  fontSize='0.8rem'
                  fontFamily='Source Sans Pro'
                  lineHeight='1.25rem'
                  color='white'
                >
                  Sancaole, Goa 403726
                </Text>
                <Text
                  fontWeight='300'
                  fontSize='0.8rem'
                  fontFamily='Source Sans Pro'
                  lineHeight='1.25rem'
                  color='white'
                >
                  Mudit: <Link to='tel:+919870013541'>+91 9870013541</Link>
                </Text>
                <Text
                  fontWeight='300'
                  fontSize='0.8rem'
                  fontFamily='Source Sans Pro'
                  lineHeight='1.25rem'
                  color='white'
                >
                  Abhrajeet: <Link to='tel:+919648264288'>+91 9648264288</Link>
                </Text>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Text
        color='white'
        align='center'
        marginTop='1rem'
        marginBottom='0.25rem'
      >
        © Copyright Quark 2023, All Rights Reserved
      </Text>
      <Text
        color='white'
        align='center'
        marginTop='0.25rem'
        marginBottom='1rem'
      >
        Made with ❤️ by <Link to={'https://devsoc.club'}>DevSoc;</Link>
      </Text>
    </Flex>
  );
};

export default QuickLinks;
