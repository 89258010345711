import { React, useState } from 'react';
import { Flex, Box, Image, Text, VStack, Link, Button } from '@chakra-ui/react';

import './Card.css';
export default function Card({ name, descr, link, imag }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Flex
      position={'relative'}
      bgColor={'transparent'}
      m='auto'
      h={{ base: '22.5rem', lg: '25rem' }}
      w={{ base: '17.5rem', lg: '20.125rem' }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      justifyContent={'center'}
      alignItems={'center'}
      outline={'white solid 1px'}
      outlineOffset={'-0.125rem'}
      overflow={'clip'}
      color={'white'}
    >
      <Box
        className={`slider-left ${isHovered ? 'slide-left' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
        zIndex={3}
      ></Box>
      <Box
        className={`slider-left-white ${isHovered ? 'slide-left-white' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
        zIndex={3}
      ></Box>
      <Box
        className={`slider-top ${isHovered ? 'slide-top' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-top-white ${isHovered ? 'slide-top-white' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-right ${isHovered ? 'slide-right' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
      ></Box>
      <Box
        className={`slider-right-white ${isHovered ? 'slide-right-white' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
      ></Box>
      <Box
        className={`slider-bottom ${isHovered ? 'slide-bottom' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-bottom-white ${
          isHovered ? 'slide-bottom-white' : ''
        }`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Flex
        height={{ base: '20rem', lg: '21.625rem' }}
        w={{ base: '15rem', lg: '16.75rem' }}
        overflow={'hidden'}
        position={'relative'}
      >
        <Flex
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={`overlay-card ${isHovered ? 'slide' : ''}`}
        >
          <Flex
            direction={'column'}
            justifyContent={'space-between'}
            w='85%'
            h='95%'
          >
            <VStack
              textAlign={'left'}
              alignItems={'flex-start'}
              spacing={'2rem'}
              mt={'1rem'}
            >
              <Text className='card-title' fontSize={'1.125rem'} mb={'1.25rem'}>
                {name}
              </Text>
              <Text
                fontSize={'0.875rem'}
                className='card-description'
                align={'left'}
              >
                {descr}
              </Text>
            </VStack>
            <Link href='https://register.bitsquark.org'>
              <Button
                className='card-register-button'
                href={link}
                fontSize={'0.875rem'}
                variant={'outline'}
                borderRadius={0}
              >
                Register Now
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Image
          src={imag}
          height={{ base: '', lg: '21.625rem' }}
          w={{ base: '', lg: '16.75rem' }}
        />
      </Flex>
    </Flex>
  );
}
