import { React, useState } from 'react';
import {
  Flex,
  Box,
  Image,
  Text,
  VStack,
  Link,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import './TeamCard.css';
export default function TeamCard({ name, desgn, dept, imag, linkedIn, insta }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Flex
      position={'relative'}
      bgColor={'transparent'}
      m='auto'
      h={{ base: '22.5rem', lg: '25rem' }}
      w={{ base: '17.5rem', lg: '20.125rem' }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      justifyContent={'center'}
      alignItems={'center'}
      outline={'white solid 1px'}
      outlineOffset={'-0.125rem'}
      overflow={'clip'}
      color={'white'}
    >
      <Box
        className={`slider-left ${isHovered ? 'slide-left' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
        zIndex={3}
      ></Box>
      <Box
        className={`slider-left-white ${isHovered ? 'slide-left-white' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
        zIndex={3}
      ></Box>
      <Box
        className={`slider-top ${isHovered ? 'slide-top' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-top-white ${isHovered ? 'slide-top-white' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-right ${isHovered ? 'slide-right' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
      ></Box>
      <Box
        className={`slider-right-white ${isHovered ? 'slide-right-white' : ''}`}
        position={'absolute'}
        h='40%'
        w='100%'
      ></Box>
      <Box
        className={`slider-bottom ${isHovered ? 'slide-bottom' : ''}`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Box
        className={`slider-bottom-white ${
          isHovered ? 'slide-bottom-white' : ''
        }`}
        position={'absolute'}
        h='100%'
        w='40%'
      ></Box>
      <Flex
        height={{ base: '20rem', lg: '21.625rem' }}
        w={{ base: '15rem', lg: '16.75rem' }}
        overflow={'hidden'}
        position={'relative'}
      >
        <Flex
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={`overlay-team ${isHovered ? 'slide' : ''}`}
        >
          <Flex direction={'column'} justifyContent={'end'} w='85%' h='95%'>
            <VStack
              textAlign={'left'}
              alignItems={'flex-start'}
              spacing={'1rem'}
            >
              <Text className='team-title' fontSize={'1.25rem'} noOfLines={3}>
                {name}
              </Text>
              <Text
                fontSize={'0.8125rem'}
                className='team-desgn'
                align={'left'}
                noOfLines={3}
              >
                {desgn}
              </Text>
              <Text fontSize={'0.625rem'} className='team-dept' align={'left'}>
                {dept}
              </Text>
              <HStack spacing={'1rem'}>
                <Link href={linkedIn} isExternal>
                  <IconButton
                    marginStart={0}
                    ml={'-0.4rem'}
                    fontSize={'2rem'}
                    h={'2rem'}
                    w={'2rem'}
                    bgColor={'transparent'}
                    icon={<FaLinkedin />}
                    _hover={{ boxShadow: '0 0 1rem white' }}
                  />
                </Link>
                <Link href={insta} isExternal>
                  <IconButton
                    marginStart={0}
                    fontSize={'2rem'}
                    h={'2rem'}
                    w={'2rem'}
                    bgColor={'transparent'}
                    icon={<FaInstagram />}
                    _hover={{ boxShadow: '0 0 1rem white' }}
                  />
                </Link>
              </HStack>
            </VStack>
          </Flex>
        </Flex>
        <Image
          src={imag}
          height={{ base: '', lg: '21.625rem' }}
          w={{ base: '', lg: '16.75rem' }}
        />
      </Flex>
    </Flex>
  );
}
