import { React, useState } from 'react';
import {
  Flex,
  Text,
  keyframes,
  Box,
  Image,
  flexbox,
  Grid,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/sponsors/background.png';
import SponsorCard from '../CardStruct/SponsorCard';
import { sponsorsData } from './SponsorsCardData';
import { MediaPartners } from './MediaPartnersData';
import scroll from '../../assets/events/scroll.png';
import './Sponsors.css';

export default function Sponsors() {
  const [bgLoad, didBgLoad] = useState(false);
  let bgStatus = () => {
    didBgLoad(true);
  };
  const animationKeyframes = keyframes`
    0% { transform: translate(0,80vh);}
    25% { transform: translate(0,60vh); }
    50% { transform: translate(0,40vh); }
    75% { transform: translate(0,20vh); }
    100% {transform: translate(0,0); }
  `;

  const animation = `${bgLoad ? animationKeyframes : ''} 1s ease-out`;
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      style={{ display: flexbox }}
    >
      <Flex flexDirection='column'>
        <Flex
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'clip'}
        >
          <Box position={'absolute'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
          </Box>
          <Text
            as={motion.text}
            animation={animation}
            transform={'translate(0,100vh)'}
            className='heading'
            fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
            sx={{ animationFillMode: 'forwards' }}
          >
            Sponsors
          </Text>
        </Flex>
        <Flex
          bgColor={'black'}
          bgGradient={
            'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.63) 0.01%, #000000 48.23%, rgba(0, 0, 0, 0.6) 100%);'
          }
          h='27.5625rem'
          mt={'-7rem'}
          mb={'-43rem'}
          zIndex={8}
          filter={'blur(1.5rem)'}
        ></Flex>
        <Flex
          h={'fit-content'}
          bgImg={scroll}
          bgPos={'center'}
          bgSize={'cover'}
          w='100%'
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex
            mt={'25rem'}
            zIndex={9}
            w={'85%'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Grid
              mt={'16rem'}
              mb={'7rem'}
              templateColumns='repeat(1, 1fr)'
              gap={'8rem'}
            >
              {sponsorsData.map(elem => {
                return (
                  <SponsorCard
                    partner={elem.partner}
                    imag={elem.imag}
                    link={elem.link}
                  />
                );
              })}
            </Grid>

            <Text
              mt={'5rem'}
              fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
              className='heading'
            >
              Our Media Partners
            </Text>
            <Grid
              mt={'8rem'}
              mb={'5rem'}
              templateColumns='repeat(1, 1fr)'
              gap={'8rem'}
            >
              {MediaPartners.map(elem => {
                return (
                  <SponsorCard
                    partner={elem.partner}
                    imag={elem.imag}
                    link={elem.link}
                  />
                );
              })}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
}
