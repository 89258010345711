import { React, useState } from 'react';
import {
  Flex,
  Text,
  keyframes,
  Image,
  Box,
  flexbox,
  Grid,
  Link,
  Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/events/background.png';
import rightRock from '../../assets/events/rightRocks.svg';
import fRocks from '../../assets/events/floatingRocks.svg';
import scroll from '../../assets/events/scroll.png';
import Card from '../CardStruct/Card';
import './Events.css';
import {
  Roboficial,
  Corporate,
  Programmers,
  Matka,
  Blockchainify,
  Design,
  School,
  Elixir,
  Electrify,
  Cubix,
  Specials,
} from './EventsCardData';
export default function Events() {
  const [bgLoad, didBgLoad] = useState(false);
  const sideRocksKeyframes = keyframes`
    0% { transform: translate(80vw,0);}
    25% { transform: translate(60vw,0); }
    50% { transform: translate(40vw,0); }
    75% { transform: translate(20vw,0); }
    100% {transform: translate(0,0); }
  `;

  const sideRocks = `${bgLoad ? sideRocksKeyframes : ''} 1s ease-out`;
  const animationKeyframes = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;
  const animation = `${bgLoad ? animationKeyframes : ''} 1s ease-out`;
  const animationKeyframes2 = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;
  const animation2 = `${bgLoad ? animationKeyframes2 : ''} 1.5s ease-out`;
  let bgStatus = () => {
    didBgLoad(true);
  };
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      style={{ display: flexbox }}
    >
      <Flex flexDirection={'column'}>
        <Flex
          zIndex={7}
          flexDirection={'column'}
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'clip'}
        >
          <Box position={'absolute'} overflowX={'clip'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
            <Box position={'absolute'} right={0} bottom={0}>
              <Flex>
                <Image
                  as={motion.img}
                  transform={'translate(100vw,0)'}
                  animation={sideRocks}
                  src={rightRock}
                  alt=''
                  sx={{ animationFillMode: 'forwards' }}
                />
              </Flex>
            </Box>
          </Box>

          <Box
            as={motion.text}
            transform={'translate(0,100vh)'}
            animation={animation}
            sx={{ animationFillMode: 'forwards' }}
            position={'relative'}
            w={{ base: '25rem', lg: '66rem' }}
            mr={{ lg: '25rem' }}
            mb={{ lg: '10rem' }}
          >
            <Text
              className='heading'
              fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
              zIndex={2}
            >
              Events
            </Text>
            <Image
              as={motion.img}
              animation={animation2}
              sx={{ animationFillMode: 'forwards' }}
              transform={'translate(0,100vh)'}
              position={'absolute'}
              src={fRocks}
              alt=''
              zIndex={3}
              inset={0}
              left={{ base: '7%', lg: '20%' }}
            />
          </Box>
        </Flex>
        <Flex
          bgColor={'black'}
          bgGradient={
            'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.63) 0.01%, #000000 48.23%, rgba(0, 0, 0, 0.6) 100%);'
          }
          h='27.5625rem'
          mt={'-5rem'}
          mb={'-30rem'}
          zIndex={8}
          filter={'blur(1.5rem)'}
        ></Flex>
        <Flex
          h={'fit-content'}
          bgImg={scroll}
          bgPos={'center'}
          bgSize={'cover'}
          w='100%'
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex
            mt={'25rem'}
            zIndex={9}
            w={'85%'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Text className='heading'>ROBOFICIAL</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Roboficial.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Corporate</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Corporate.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Programmers inc</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Programmers.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Matka</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Matka.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Blockchainify</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Blockchainify.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Design And Build</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Design.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>School Bag</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {School.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Elixir</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Elixir.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Electrify</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Electrify.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Cubix</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Cubix.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Text className='heading'>Specials</Text>
            <Grid
              mt={'3rem'}
              mb={'7rem'}
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2,1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: '3rem', lg: '8rem' }}
            >
              {Specials.map(elem => {
                return (
                  <Card
                    name={elem.name}
                    descr={elem.descr}
                    link={elem.link}
                    imag={elem.imag}
                  />
                );
              })}
            </Grid>
            <Link
              href='https://drive.google.com/file/d/1GqyPj7WSxv_2_xJXtu-v6EnA3rC248yo/preview'
              target={'_blank'}
            >
              <Button
                className='card-rulebook-button'
                fontSize={'1rem'}
                variant={'outline'}
                borderRadius={0}
                my={'3rem'}
              >
                Rulebook
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
}
