import abhrajeet from '../../assets/team/teamPotraits/abhrajeet.png';
import abhijith from '../../assets/team/teamPotraits/abhijith.png';
import aman from '../../assets/team/teamPotraits/aman.png';
import ananya from '../../assets/team/teamPotraits/ananya.png';
import ansh from '../../assets/team/teamPotraits/ansh.png';
import arnav from '../../assets/team/teamPotraits/arnav.png';
import aryan from '../../assets/team/teamPotraits/aryan.png';
import aryansh from '../../assets/team/teamPotraits/aryansh.png';
import aviral from '../../assets/team/teamPotraits/aviral.png';
import dhruv from '../../assets/team/teamPotraits/dhruv.png';
import dhruvTuli from '../../assets/team/teamPotraits/dhruvTuli.png';
import dinesh from '../../assets/team/teamPotraits/dinesh.png';
import jahnvi from '../../assets/team/teamPotraits/jahnvi.png';
import jatin from '../../assets/team/teamPotraits/jatin.png';
import jimit from '../../assets/team/teamPotraits/jimit.png';
import manank from '../../assets/team/teamPotraits/manank.png';
import mandar from '../../assets/team/teamPotraits/mandar.png';
import megh from '../../assets/team/teamPotraits/megh.png';
import mudit from '../../assets/team/teamPotraits/mudit.png';
import prakhar from '../../assets/team/teamPotraits/prakhar.png';
import prathamesh from '../../assets/team/teamPotraits/prathamesh.png';
import rahil from '../../assets/team/teamPotraits/rahil.png';
import rudraraju from '../../assets/team/teamPotraits/rudraraju.png';
import thomas from '../../assets/team/teamPotraits/thomas.png';
import yash from '../../assets/team/teamPotraits/yash.png';
import bhavya from '../../assets/team/teamPotraits/bhavya.png';
export const teamData = [
  {
    name: 'aman anand',
    desgn: 'convener',
    dept: 'quark',
    linkedIn: 'https://www.linkedin.com/in/aman-anand-9226a720a',
    insta: 'https://instagram.com/aman.a.rajput?igshid=ZDdkNTZiNTM=',
    imag: `${aman}`,
  },
  {
    name: 'abhrajeet mukherjee',
    desgn: 'chief and events head',
    dept: 'quark controls',
    linkedIn: 'https://www.linkedin.com/in/abhrajeet-m-a1b3b7104',
    insta: 'https://instagram.com/_abhra._?igshid=ZDdkNTZiNTM=',
    imag: `${abhrajeet}`,
  },
  {
    name: 'jimit shah',
    desgn: 'design and content head',
    dept: 'quark controls',
    linkedIn: 'https://www.linkedin.com/in/jimit-shah-b831781aa/',
    insta: 'https://www.instagram.com/jimit_shah04/',
    imag: `${jimit}`,
  },
  {
    name: 'jatin manchanda',
    desgn: 'aurora nights and intiatives head',
    dept: 'quark controls',
    linkedIn: 'https://www.linkedin.com/in/jatin-manchanda/',
    insta: 'https://www.instagram.com/jatinm2412/',
    imag: `${jatin}`,
  },
  {
    name: 'mandar joshi',
    desgn: 'workshops and operations head',
    dept: 'quark controls',
    linkedIn: 'https://www.linkedin.com/in/mandar-joshi-8073b71b3/',
    insta: 'https://instagram.com/_mandar_jo?igshid=ZDdkNTZiNTM=',
    imag: `${mandar}`,
  },
  {
    name: 'prakhar karode',
    desgn: 'exhibitions and guest lectures head',
    dept: 'quark controls',
    linkedIn: 'https://www.linkedin.com/in/prakhar-karode-84998b217',
    insta: 'https://instagram.com/prakhar1006?igshid=ZDdkNTZiNTM=',
    imag: `${prakhar}`,
  },
  {
    name: 'megh gupta',
    desgn: 'chief coordinator',
    dept: 'backstage',
    linkedIn: 'https://www.linkedin.com/in/megh-gupta-917280200',
    insta: 'https://www.instagram.com/meghxgupta/?hl=en',
    imag: `${megh}`,
  },
  {
    name: 'ananya iyer',
    desgn: 'quark coordinator',
    dept: 'backstage',
    linkedIn: 'https://www.linkedin.com/in/ananya-iyer-24233b205',
    insta: 'https://instagram.com/ananyaaiyerr?igshid=ZDdkNTZiNTM=',
    imag: `${ananya}`,
  },
  {
    name: 'dhruv rohira',
    desgn: 'technical advisor and research head',
    dept: 'backstage',
    linkedIn: 'https://www.linkedin.com/in/dhruv-rohira-b85108205',
    insta: 'https://instagram.com/rohira_dhruv',
    imag: `${dhruv}`,
  },
  {
    name: 'yash dabhade',
    desgn: 'chief coordinator',
    dept: 'dosm',
    linkedIn: 'https://in.linkedin.com/in/yash-dabhade-aa0297137',
    insta: 'https://instagram.com/yashdabhade_?igshid=YmMyMTA2M2Y=',
    imag: `${yash}`,
  },
  {
    name: 'aryan kuhad',
    desgn: 'sponsorship and marketing head',
    dept: 'dosm',
    linkedIn: 'https://www.linkedin.com/in/aryan-kuhad-4b2aa91b8/',
    insta: 'https://www.instagram.com/aryankuhad/?hl=en',
    imag: `${aryan}`,
  },
  {
    name: 'Bhavya somani',
    desgn: 'marketing head',
    dept: 'dosm',
    linkedIn: 'https://www.linkedin.com/in/bhavya-somani-662b94204/',
    insta: 'https://www.instagram.com/bhavya_somani07/',
    imag: `${bhavya}`,
  },
  {
    name: 'aviral singhal',
    desgn: 'chief coordinator',
    dept: 'depp',
    linkedIn: 'https://www.linkedin.com/in/aviral-singhal-8841ab175',
    insta: 'https://www.instagram.com/singhal_aviral',
    imag: `${aviral}`,
  },
  {
    name: 'mudit jaju',
    desgn: 'quark pr head',
    dept: 'depp',
    linkedIn: 'https://www.linkedin.com/mwlite/in/mudit-jaju-74b222252',
    insta: 'https://instagram.com/mudit_jaju?igshid=MjkzY2Y1YTY=',
    imag: `${mudit}`,
  },
  {
    name: 'manank patel',
    desgn: 'chief coordinator',
    dept: 'dopy',
    linkedIn: 'https://www.linkedin.com/in/manank20',
    insta: 'https://www.instagram.com/manank_p',
    imag: `${manank}`,
  },
  {
    name: 'thomas biju',
    desgn: 'quark coordinator',
    dept: 'dopy',
    linkedIn: 'https://www.linkedin.com/in/thomas-b-6480751b9',
    insta: 'https://instagram.com/thomas_1o?igshid=ZDdkNTZiNTM=',
    imag: `${thomas}`,
  },
  {
    name: 'arnav borkar',
    desgn: 'secretary-general',
    dept: 'bitsmun',
    linkedIn: 'https://www.linkedin.com/in/arnavborkar/',
    insta: 'https://www.instagram.com/arnav.borkar1811/',
    imag: `${arnav}`,
  },
  {
    name: 'jahnvi rajimwale',
    desgn: 'director-general',
    dept: 'bitsmun',
    linkedIn: 'https://www.linkedin.com/in/janhvi-rajimwale-b8500a209',
    insta: 'https://instagram.com/jan_060203?igshid=YmMyMTA2M2Y=',
    imag: `${jahnvi}`,
  },
  {
    name: 'prathamesh pingle',
    desgn: 'cheif coordinator',
    dept: 'docw',
    linkedIn: 'https://www.linkedin.com/in/prathameshpin',
    insta: 'https://instagram.com/prathamesh_pingle_?igshid=ZDdkNTZiNTM=',
    imag: `${prathamesh}`,
  },
  {
    name: 'aryansh tiwari',
    desgn: 'chief coordinator',
    dept: 'AND',
    linkedIn: 'https://www.linkedin.com/in/aryansh-tiwari-61a238268',
    insta: 'https://instagram.com/aryanshhhh_03?igshid=ZDdkNTZiNTM=',
    imag: `${aryansh}`,
  },
  {
    name: 'ansh bhardwaj',
    desgn: 'quark coordinator',
    dept: 'AND',
    linkedIn: 'https://in.linkedin.com/in/ansh-bhardwaj',
    insta: 'https://instagram.com/notanshb',
    imag: `${ansh}`,
  },
  {
    name: 'dhruv Tuli',
    desgn: 'finance head',
    dept: 'dofam',
    linkedIn: 'https://www.linkedin.com/in/dhruv-tuli-071258ba/',
    insta: 'https://www.instagram.com/dhruv_tuli/?next=%2F',
    imag: `${dhruvTuli}`,
  },
  {
    name: 'rahil shah',
    desgn: 'chief coordinator',
    dept: 'domac',
    linkedIn:
      'https://www.linkedin.com/in/rahil-shah-533595202?originalSubdomain=in',
    insta: 'https://instagram.com/rahilshah_511?igshid=MjkzY2Y1YTY=',
    imag: `${rahil}`,
  },
  {
    name: 'abhijith menon',
    desgn: 'quark media head',
    dept: 'domac',
    linkedIn: 'https://www.linkedin.com/in/abijith-sarath-menon-42b1bb209',
    insta: 'https://instagram.com/jithsmenon_?igshid=ZDdkNTZiNTM=',
    imag: `${abhijith}`,
  },
  {
    name: 'tummalapalli dinesh',
    desgn: 'chief coordinator',
    dept: 'dosar',
    linkedIn: 'https://www.linkedin.com/in/tummalapalli-dinesh/',
    insta: 'https://www.instagram.com/dineshtummalapalli/',
    imag: `${dinesh}`,
  },
  {
    name: 'rudraraju varma',
    desgn: 'quark coordinator',
    dept: 'dosar',
    linkedIn: 'https://www.linkedin.com/in/ashish-varma-366b44199',
    insta: 'https://instagram.com/ashish_varma.7?igshid=ZDdkNTZiNTM=',
    imag: `${rudraraju}`,
  },
];
