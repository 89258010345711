import { Flex, Image, Text, VStack, HStack, Link } from '@chakra-ui/react';
import { React, useState } from 'react';
import './CarouselCard.css';

export default function CarouselCard({ title, desc, imag, moreText, link }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Flex
      position={'relative'}
      bgColor={'transparent'}
      m='auto'
      h={{ base: '15.5rem', md: '35rem' }}
      w={{ base: '22rem', md: '47.5rem' }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      justifyContent={'center'}
      alignItems={'center'}
      outline={'white solid 1px'}
      outlineOffset={'-0.125rem'}
      overflow={'clip'}
      color={'white'}
    >
      <Flex
        height={{ base: '14rem', md: '31.25rem' }}
        w={{ base: '20rem', md: '43.75rem' }}
        overflow={'hidden'}
        position={'relative'}
      >
        <Flex
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={`overlay ${isHovered ? 'slide' : ''}`}
        >
          <Flex direction={'row'} w='85%' h='95%' alignItems={'end'}>
            <VStack
              textAlign={'left'}
              alignItems={'flex-start'}
              spacing={{ base: '0.5rem', md: '1rem' }}
            >
              <Text className='card-title'>{title}</Text>
              <Text
                className='card-description'
                align={'left'}
                fontSize={'0.85rem'}
              >
                {desc}
              </Text>
              <HStack spacing={{ base: '2rem', md: '8rem' }}>
                <Link href={link}>
                  <Text className='hover-link'>{moreText}</Text>
                </Link>
                <Link href='https://register.bitsquark.org'>
                  <Text className='hover-link'>Register</Text>
                </Link>
              </HStack>
            </VStack>
          </Flex>
        </Flex>
        <Image
          src={imag}
          height={{ base: '16rem', md: '31.25rem' }}
          w={{ base: '22rem', md: '43.75rem' }}
          objectFit={'cover'}
        />
      </Flex>
    </Flex>
  );
}
