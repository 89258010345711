import robowars from '../../assets/cards/robowars.png';
import roborace from '../../assets/cards/roborace.png';
import robosoccer from '../../assets/cards/robosoccer.png';
import mazerunner from '../../assets/cards/mazerunner.png';
import timechallenge from '../../assets/cards/timechallenge.png';
import roboclench from '../../assets/cards/roboclench.png';
import bullion from '../../assets/cards/bullion.png';
import casecrack from '../../assets/cards/casecrack.png';
import policymav from '../../assets/cards/policymav.png';
import regalia from '../../assets/cards/regalia.png';
import bitsCTF from '../../assets/cards/bitsCTF.png';
import codejam from '../../assets/cards/codejam.png';
import codewars from '../../assets/cards/codewars.png';
import foolscoding from '../../assets/cards/foolscoding.png';
import hackathon from '../../assets/cards/hackathon.png';
import valorant from '../../assets/cards/valorant.png';
import fifa from '../../assets/cards/fifa.png';
import dappathon from '../../assets/cards/dappathon.png';
import anftee from '../../assets/cards/anftee.png';
import cryptomania from '../../assets/cards/cryptomania.png';
import burnout from '../../assets/cards/burnout.png';
import searchAndRescue from '../../assets/cards/searchAndRescue.png';
import trailblazers from '../../assets/cards/trailblazers.png';
import quiz from '../../assets/cards/quiz.png';
import debate from '../../assets/cards/debate.png';
import enigmatica from '../../assets/cards/enigmatica.png';
import ganimatoonics from '../../assets/cards/ganimatoonics.png';
import quarknationalquiz from '../../assets/cards/quarknationalquiz.png';
import digilogica from '../../assets/cards/digilogica.png';
import ucmania from '../../assets/cards/ucmania.png';
import cubix from '../../assets/cards/cubix.png';
import paper from '../../assets/cards/paper.png';

export const Roboficial = [
  {
    name: 'Robowars',
    descr:
      'A metal-crushing,circuit-frying battle royale at robowars:The ultimate test of robotic supremacy',
    link: '',
    imag: `${robowars}`,
  },
  {
    name: 'Roborace',
    descr:
      'Experience the thrill of the race as cutting-edge robots compete for the top spot in a lightning-fast dash to the finish line.',
    link: '',
    imag: `${roborace}`,
  },
  {
    name: 'Robosoccer',
    descr:
      "A mind-boggling display of robotic athleticism at RoboSoccer: The world's most advanced robots competing for glory on the field",
    link: '',
    imag: `${robosoccer}`,
  },
  {
    name: 'time challenge',
    descr:
      "Get ready to put your engineering skills to the test at Time Challenge, where you'll build a lightning-fast line follower that can tackle any circuit and race to the finish line in record time.",
    link: '',
    imag: `${timechallenge}`,
  },
  {
    name: 'Roboclench',
    descr:
      'Get ready to witness the ultimate test of robotic dexterity as machines with claws battle it out to pick up and hold on tight to victory at RoboClench',
    link: '',
    imag: `${roboclench}`,
  },
  {
    name: 'Mazerunner',
    descr:
      "If you're passionate about robotics and love a good challenge, then Maze Runner is the event for you! Build a line follower that can flawlessly follow any circuit and put your skills to the test.",
    link: '',
    imag: `${mazerunner}`,
  },
];

export const Corporate = [
  {
    name: 'Regalia',
    descr:
      'Join us at Regalia to be held during Quark, the biggest techno-management fest of India held at BITS-Pilani, Goa Campus and manifest your dream of having a shark tank like experience.',
    link: '',
    imag: `${regalia}`,
  },
  {
    name: 'Case crackdown',
    descr:
      'Calling all aspiring strategists and marketers! Join Case Crackdown and take on real-world business challenges.',
    link: '',
    imag: `${casecrack}`,
  },
  {
    name: 'Policy Mavericks',
    descr:
      'Ready to take on a challenge? Join Policy Mavericks, the premier business case study competition, and put your skills to the test.',
    link: '',
    imag: `${policymav}`,
  },
  {
    name: 'Bullion beatdown',
    descr:
      'Put your trading skills to the test in our intense market competition featuring gold trading and negotiations',
    link: '',
    imag: `${bullion}`,
  },
];

export const Programmers = [
  {
    name: 'Codejam',
    descr:
      'Calling all coding enthusiasts! Compete with the brightest minds and test your coding skills in our upcoming coding competition!',
    link: '',
    imag: `${codejam}`,
  },
  {
    name: 'fools coding',
    descr:
      'Be the Bond of programming and figure out what is the hidden source code by looking at its behavior on your inputs.',
    link: '',
    imag: `${foolscoding}`,
  },
  {
    name: 'hackathon',
    descr:
      'Calling all developers, designers, and tech enthusiasts! Come and showcase your skills at our upcoming hackathon and compete for exciting prizes!',
    link: '',
    imag: `${hackathon}`,
  },
  {
    name: 'codewars',
    descr:
      'Are you ready for a coding challenge like no other? Join our 1v1 event where solving problems not only earns you points, but also strategically prevents your opponent from gaining them',
    link: '',
    imag: `${codewars}`,
  },
  {
    name: 'bits ctf',
    descr:
      'Are you a hacker, coder, or just passionate about cybersecurity? Compete in our Capture the Flag and see if you have what it takes to come out on top',
    link: '',
    imag: `${bitsCTF}`,
  },
];

export const Matka = [
  {
    name: 'valorant',
    descr:
      'Get your team ready and join the ultimate Valorant tournament. Prove your worth, master your aim, and become the champion. Are you up for the challenge?',
    link: '',
    imag: `${valorant}`,
  },
  {
    name: 'fifa',
    descr:
      "Are you ready to take on the world's best and claim victory? The arena is waiting for you, so lace up your boots and let's play!",
    link: '',
    imag: `${fifa}`,
  },
];
export const Blockchainify = [
  {
    name: 'dapp-athon',
    descr:
      "Join us for dApp-athon, where you'll compete to create the best and most innovative decentralized applications based on themed challenges that showcase the latest in blockchain technology.",
    link: '',
    imag: `${dappathon}`,
  },
  {
    name: 'cryptomania',
    descr:
      'Are you a crypto trading pro? Put your skills to the test and compete to win big in our upcoming competition. Join now.',
    link: '',
    imag: `${cryptomania}`,
  },
  {
    name: 'anftee',
    descr:
      'Get ready to soar to new heights and blaze your own trail in the world of aviation - join us for Trailblazers, the thrilling plane-building competition where speed and agility reign supreme!',
    link: '',
    imag: `${anftee}`,
  },
];
export const Design = [
  {
    name: 'burnout',
    descr:
      'Get ready to burn rubber and leave the competition in the dust at Burnout, the electrifying robot car racing event!',
    link: '',
    imag: `${burnout}`,
  },
  {
    name: 'Search and rescue',
    descr:
      "Are you ready to put your engineering skills to the test and make a difference in the world? Join us at 'Search and Rescue' and design a drone to deliver critical medical supplies in record time!",
    link: '',
    imag: `${searchAndRescue}`,
  },
  {
    name: 'trailblazers',
    descr:
      'Get ready to soar to new heights and blaze your own trail in the world of aviation - join us for Trailblazers, the thrilling plane-building competition where speed and agility reign supreme!',
    link: '',
    imag: `${trailblazers}`,
  },
];
export const School = [
  {
    name: 'quiz',
    descr:
      'Join us on campus to witness the culmination of months of hard work - a group project presentation showcasing innovative ideas and cutting-edge technology',
    link: '',
    imag: `${quiz}`,
  },
  {
    name: 'debate',
    descr:
      'Join us for the Schoolbag 2023 Debate and showcase your verbal prowess in a battle of wits and rebuttals - the ultimate test of your persuasive skills!',
    link: '',
    imag: `${debate}`,
  },
  {
    name: 'enigmatica',
    descr:
      'Join us for Enigmatica - an exciting and challenging exam that requires no prerequisites and puts your mental prowess to the ultimate test!',
    link: '',
    imag: `${enigmatica}`,
  },
];
export const Elixir = [
  {
    name: 'ganimatoonics',
    descr:
      'Get ready to test your knowledge and unravel the secrets of Ganimatoonics with our exciting quiz - an adventure awaits!',
    link: '',
    imag: `${ganimatoonics}`,
  },
  {
    name: 'quark national quiz',
    descr:
      "Are you ready to put your grey matter to the test? Join us at Quark'23 National Quiz and compete against the brightest minds in the nation for a chance to win exciting prizes!",
    link: '',
    imag: `${quarknationalquiz}`,
  },
];
export const Electrify = [
  {
    name: 'digilogica',
    descr:
      'Do you think that Digital Design is just a boring course , just about gates and logic? Well, you’re in for a huge surprise!',
    link: '',
    imag: `${digilogica}`,
  },
  {
    name: 'μC MANIA',
    descr:
      'This event is very flexible with the microcontrollers the participant wants to use. Test your Arduino skills and race to the top!',
    link: '',
    imag: `${ucmania}`,
  },
];
export const Cubix = [
  {
    name: 'Cubix',
    descr: 'Twist, Turn, and Triumph: The Ultimate Rubix Cube Showdown',
    link: '',
    imag: `${cubix}`,
  },
];
export const Specials = [
  {
    name: 'paper presentation',
    descr:
      "Get ready to put your research skills to the test and compete for a chance to present your paper at our Paper Presentation! Submit your abstract now and show us what you've got.",
    link: '',
    imag: `${paper}`,
  },
];
