import { React, useState } from 'react';
import { Flex, Box, Image, Text, Link } from '@chakra-ui/react';
import './Card.css';
export default function SponsorCard({ partner, imag, link }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Flex
      mb={{ base: '3rem', lg: '5rem' }}
      flexDirection={'column'}
      fontSize={{ base: '1.4rem', lg: '3.0625rem' }}
      textAlign={'center'}
    >
      <Text
        maxW={'52rem'}
        noOfLines={{ base: '5', lg: '4' }}
        className='heading'
        mb={{ base: '3rem', lg: '5rem' }}
        fontSize={{ base: '1.7rem', lg: '3.0625rem' }}
      >
        {partner}
      </Text>
      <Link href={link}>
        <Flex
          position={'relative'}
          bgColor={'transparent'}
          m='auto'
          h={{ base: '11rem', lg: '22.0625rem' }}
          w={{ base: '16.5rem', lg: '25rem' }}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          justifyContent={'center'}
          alignItems={'center'}
          outline={'white solid 1px'}
          outlineOffset={'-0.125rem'}
          overflow={'clip'}
          color={'white'}
        >
          <Box
            className={`slider-left ${isHovered ? 'slide-left' : ''}`}
            position={'absolute'}
            h='40%'
            w='100%'
            zIndex={3}
          ></Box>
          <Box
            className={`slider-left-white ${
              isHovered ? 'slide-left-white' : ''
            }`}
            position={'absolute'}
            h='40%'
            w='100%'
            zIndex={3}
          ></Box>
          <Box
            className={`slider-top ${isHovered ? 'slide-top' : ''}`}
            position={'absolute'}
            h='100%'
            w='40%'
          ></Box>
          <Box
            className={`slider-top-white ${isHovered ? 'slide-top-white' : ''}`}
            position={'absolute'}
            h='100%'
            w='40%'
          ></Box>
          <Box
            className={`slider-right ${isHovered ? 'slide-right' : ''}`}
            position={'absolute'}
            h='40%'
            w='100%'
          ></Box>
          <Box
            className={`slider-right-white ${
              isHovered ? 'slide-right-white' : ''
            }`}
            position={'absolute'}
            h='40%'
            w='100%'
          ></Box>
          <Box
            className={`slider-bottom ${isHovered ? 'slide-bottom' : ''}`}
            position={'absolute'}
            h='100%'
            w='40%'
          ></Box>
          <Box
            className={`slider-bottom-white ${
              isHovered ? 'slide-bottom-white' : ''
            }`}
            position={'absolute'}
            h='100%'
            w='40%'
          ></Box>
          <Image src={imag} height={'80%'} w={'80%'} objectFit={'contain'} />
        </Flex>
      </Link>
    </Flex>
  );
}
