import { React, useState } from 'react';
import {
  Flex,
  Text,
  keyframes,
  Image,
  Box,
  VStack,
  flexbox,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bg from '../../assets/nights/background.png';
import fRocks from '../../assets/nights/floatingRocks.svg';
import nikhil from '../../assets/nights/nikhil.jfif';
import cs1 from '../../assets/nights/cs1.png';
// import cs2 from '../../assets/nights/cs2.png';
import './Nights.css';
import Night from './NightsArtist';
import darshanRaval from '../../assets/nights/darshanRaval.png';

export default function Nights() {
  const [bgLoad, didBgLoad] = useState(false);
  let bgStatus = () => {
    didBgLoad(true);
  };
  const animationKeyframes = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation = `${bgLoad ? animationKeyframes : ''} 1s ease-out`;
  const animationKeyframes2 = keyframes`
  0% { transform: translate(0,80vh);}
  25% { transform: translate(0,60vh); }
  50% { transform: translate(0,40vh); }
  75% { transform: translate(0,20vh); }
  100% {transform: translate(0,0); }
`;

  const animation2 = `${bgLoad ? animationKeyframes2 : ''} 1.5s ease-out`;
  return (
    <>
      <motion.div
        initial={{ x: 100 }}
        animate={{ x: 0 }}
        exit={{ x: -100 }}
        style={{ display: flexbox }}
      >
        <Flex
          flexDirection={'column'}
          h={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'hidden'}
        >
          <Box position={'absolute'}>
            <Image
              position={'relative'}
              src={bg}
              h='100vh'
              w='100vw'
              onLoad={bgStatus}
            />
          </Box>
          <Box
            w={{ base: '25rem', lg: '66rem' }}
            as={motion.text}
            transform={'translate(0,100vh)'}
            animation={animation}
            sx={{ animationFillMode: 'forwards' }}
            position={'relative'}
          >
            <VStack>
              <Text
                mt={'2rem'}
                className='heading'
                fontSize={{ base: '2.1875rem', lg: '5.263rem' }}
                zIndex={2}
                mb={'-4rem'}
              >
                Nights
              </Text>
            </VStack>
            <Image
              as={motion.img}
              animation={animation2}
              sx={{ animationFillMode: 'forwards' }}
              transform={'translate(0,100vh)'}
              position={'absolute'}
              src={fRocks}
              alt=''
              zIndex={3}
              inset={0}
              left={{ base: '10%', lg: '20%' }}
            />
          </Box>
        </Flex>
      </motion.div>
      <Flex
        bgGradient={
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 1) 10%,rgba(0,0,0,1) 90%, rgba(0, 0, 0, 0) 100%);'
        }
        h='15.5625rem'
        mt={'-2rem'}
        mb={'-2rem'}
        zIndex={10}
        position='relative'
      ></Flex>
      <Night
        artist={`nikhil d’souza`}
        image={nikhil}
        day={1}
        description={`Nikhil D'Souza is an Indian singer-songwriter known for his soulful and melodious voice. Some of his best songs include "Shaam" and "Mere Bina". He has also collaborated with renowned artists like A.R. Rahman and Vishal-Shekhar. Nikhil has won multiple awards for his music, including the GIMA award for Best Debutant Singer.`}
        comingSoon={false}
      />
      <Night artist={`nikhil d’souza`} image={cs1} day={2} comingSoon={true} />
      <Night
        artist={`Darshan Raval`}
        image={darshanRaval}
        day={3}
        comingSoon={false}
        day3={true}
        description={
          "Darshan Raval is an Indian singer, songwriter, composer, and actor. He gained fame in 2014 as a contestant on the Indian reality show 'India's Raw Star' and has since released numerous hit songs in various languages, including Hindi, Gujarati, and Punjabi. Some of his popular songs include 'Chogada', 'Tera Zikr', 'Baarish Lete Aana', and 'Bhula Dunga'. He has won several awards for his music, including the Best Indie Artist award at the MTV Europe Music Awards in 2020."
        }
      />
    </>
  );
}
