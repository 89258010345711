import unstop from '../../assets/sponsors/unstop.png';
import inshorts from '../../assets/sponsors/inshorts.png';
import gpdm from '../../assets/sponsors/gpdm.png';
import incrediblegoa from '../../assets/sponsors/incrediblegoa.png';
import goaPrism from '../../assets/sponsors/goaPrism.png';
import frontlines from '../../assets/sponsors/frontLines.png';
import edtime from '../../assets/sponsors/edTime.png';
import medhavi from '../../assets/sponsors/medhavi.png';
import kayem from '../../assets/sponsors/kayem.jpeg';
import aapkatimes from '../../assets/sponsors/aapkaTimes.jpeg';
import campustimes from '../../assets/sponsors/campusTimes.png';
import geeksgod from '../../assets/sponsors/geeksGod.png';
import indiacollegefest from '../../assets/sponsors/indiaCollegeFest.jpeg';
import knowafest from '../../assets/sponsors/knowafest.png';
import startupstory from '../../assets/sponsors/startupStory.png';
export const MediaPartners = [
  {
    partner: 'associate media partner',
    imag: `${inshorts}`,
    link: 'https://www.inshorts.com/',
  },
  {
    partner: 'official e-platform partner',
    imag: `${unstop}`,
    link: 'https://unstop.com/',
  },
  {
    partner: 'Official media partner',
    imag: `${gpdm}`,
    link: 'https://www.facebook.com/GPDMCOMPANY/?locale=hi_IN',
  },
  {
    partner: 'official magazine partner',
    imag: `${incrediblegoa}`,
    link: 'https://www.incrediblegoa.org/',
  },
  {
    partner: 'associate media partner',
    imag: `${goaPrism}`,
    link: 'https://www.goaprism.com/',
  },
  {
    partner: 'associate media partner',
    imag: `${frontlines}`,
    link: 'https://frontlinesmedia.in/',
  },
  {
    partner: 'associate media partner',
    imag: `${edtime}`,
    link: 'https://edtimes.in/',
  },
  {
    partner: 'online outreach partner',
    imag: `${medhavi}`,
    link: 'https://medhaavidigital.com/',
  },
  {
    partner: 'associate media partner',
    imag: `${kayem}`,
    link: 'https://www.instagram.com/kayem_times/?hl=en',
  },
  {
    partner: 'associate media partner',
    imag: `${aapkatimes}`,
    link: 'https://www.instagram.com/aapkatimes/?hl=en',
  },
  {
    partner: 'associate media partner',
    imag: `${campustimes}`,
    link: 'https://www.campustimespune.com/',
  },
  {
    partner: 'associate media partner',
    imag: `${geeksgod}`,
    link: 'https://geeksgod.com/',
  },
  {
    partner: 'associate media partner',
    link: 'https://indiacollegefest.com/',
    imag: indiacollegefest,
  },
  {
    partner: 'associate media partner',
    link: 'https://www.knowafest.com/',
    imag: knowafest,
  },
  {
    partner: 'associate media partner',
    link: 'https://startupstorymedia.com/',
    imag: startupstory,
  },
];
