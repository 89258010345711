import { React } from 'react';
import { Box, Flex, Link, Text, VStack } from '@chakra-ui/react';
// import HomeAnim from './HomeAnim';
import scroll from '../../assets/events/scroll.png';
import './Home.css';
import YouTube from 'react-youtube';
import SwiperCoverflow from '../CardStruct/SwiperCoverflow';
import HomeAnimNew from './HomeAnimNew';
export default function HomePage({ click, setClick }) {
  return (
    <Flex direction={'column'} overflowX={'clip'}>
      <Flex h='100vh' w={'100vw'}>
        <HomeAnimNew click={click} setClick={setClick} />
      </Flex>
      <Flex
        display={click ? 'flex' : 'none'}
        bgGradient={
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 48.23%, rgba(0, 0, 0, 0) 100%);'
        }
        h={'27.5625rem'}
        mt={{ base: click ? '-10rem' : '0rem', lg: click ? '-12rem' : '0rem' }}
        mb={{ base: click ? '-8rem' : '0rem', lg: click ? '-6rem' : '0rem' }}
        zIndex={9}
      ></Flex>
      <Flex
        display={click ? 'flex' : 'none'}
        mt={'-10rem'}
        bgImage={scroll}
        bgPos={'center'}
        bgSize={'cover'}
        direction={'column'}
        alignItems={'center'}
      >
        <Flex
          zIndex={9}
          color={'white'}
          direction={'column'}
          alignItems={'flex-start'}
          w={{ base: '85%' }}
          gap='2.4rem'
        >
          <Text
            mt={'15rem'}
            className='heading'
            as={'h1'}
            fontSize={{ base: '2rem', md: '3.0625rem' }}
            fontWeight={'400'}
            id={'about'}
          >
            About
          </Text>
          <Text
            align={'justify'}
            fontSize={'1rem'}
            fontWeight={'400'}
            mb={'4rem'}
          >
            Quark is the annual technical festival of BITS, Pilani-K.K. Birla
            Goa Campus,one of the biggest festivals of ots kind in India. From
            its modest beginnings in 2006, Quark morphed into a national level
            festival in its 2008 version. Since then,Quark has consistently seen
            participation from all leading colleges across India. Quark usually
            features varioues events pertaining to the different engineering
            branches as well as the pure sciences. As cryptocurrency exchanges
            and trading are widely happening around the world, the market will
            require a lot of engineers and software talents to build these
            exchanges. Many engineers are building their career with crypto
            robots and bitcoin apps. Bitcoin profit is one such crypto robot
            that has become very popular. Read the bitcoin profit review to
            learn more about these robots. With a plethora of events to cater to
            students of varied backgrounds and disciplines, including a lecture
            by the eminent former president president Dr. A.P.J Abdul Kalam,
            Nobel laureates like Sir Harry Kroto and Douglas Osheroff, events
            like the BITS Model United Nations and a number of diverse workshops
            organized by various eminent associations. Quark has carved a niche
            for itself as one of the biggest technical fest in India.Quark is
            the annual technical festival of BITS Pilani-K.K. Birla Goa Campus,
            one of the biggest festivals of its kind in India. From its modest
            beginnings in 2006, Quark morphed into a national level festival in
            its 2008 version. Since then, Quark has consistently seen
            participation from all leading colleges across India. Quark usually
            features various events pertaining to the different engineering
            branches as well as the pure sciences. As cryptocurrency exchanges
            and trading are widely happening around the world, the market will
            require a lot of engineers and software talents to build these
            exchanges. Many engineers are building their career with crypto
            robots and bitcoin apps. Bitcoin profit is one such crypto robot
            that has become very popular. Read the bitcoin profit review to
            learn more about these robots. With a plethora of events to cater to
            students of varied backgrounds and disciplines, including a lecture
            by the eminent former president Dr. A.P.J Abdul Kalam, Nobel
            laureates like Sir Harry Kroto and Douglas Osheroff, events like the
            BITS Model United Nations and a number of diverse workshops
            organized by various eminent associations, Quark has carved a niche
            for itself as one of the biggest technical fest in India.
          </Text>
          <Flex wrap={'wrap'} justifyContent='space-around' width={'100%'}>
            <Box textAlign={'center'}>
              <Text
                className='heading'
                as={'h1'}
                fontSize={{ base: '1.4rem', md: '2rem' }}
                fontWeight={'400'}
              >
                Quark '20 aftermovie
              </Text>
              <YouTube
                title='Theme Release 2023'
                videoId='wPQaUuhZv-w'
                opts={{
                  width: Math.min(
                    Math.round(visualViewport.width * 0.95),
                    640 * 0.9
                  ),
                  height: Math.min(
                    Math.round((visualViewport.width * 0.95 * 9) / 16),
                    360 * 0.9
                  ),
                }}
              />
            </Box>
            <Box textAlign={'center'}>
              <Text
                className='heading'
                as={'h1'}
                fontSize={{ base: '1.4rem', md: '2rem' }}
                fontWeight={'400'}
              >
                Quark '23 Theme Release
              </Text>
              <YouTube
                title='Aftermovie'
                videoId='5trK4xK8VLE'
                opts={{
                  width: Math.min(
                    Math.round(visualViewport.width * 0.95),
                    640 * 0.9
                  ),
                  height: Math.min(
                    Math.round((visualViewport.width * 0.95 * 9) / 16),
                    360 * 0.9
                  ),
                }}
              />
            </Box>
          </Flex>
        </Flex>
        <Box my='3.5rem' mt='7rem'>
          <SwiperCoverflow />
        </Box>
        <VStack spacing={'2rem'} mt={'8rem'} mb={'8rem'}>
          <Text className='timer' fontSize={{ base: '1.8rem', lg: '3.75rem' }}>
            24 - 26th March
          </Text>
          <Text className='timer' fontSize={{ base: '2rem', lg: '4.375rem' }}>
            The future awaits
          </Text>
          <Link
            href='https://register.bitsquark.org'
            isExternal
            className='timer'
            fontSize={{ base: '1rem', lg: '2rem' }}
          >
            Register Now
          </Link>
        </VStack>
      </Flex>
    </Flex>
  );
}
